import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { getDashboadTables } from "../../../axios/api";
import { Badge, CircularProgress } from "@mui/material";
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';

const Calender = (props) => {
  const [value, setValue] = useState(dayjs());
    const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState([])
  const handleTableData = async () => {
    setIsLoading(true);
    await getDashboadTables(100)
      .then((res) => {
        setIsLoading(false);

        setData(res?.data?.result)
      })
      .catch((err) => {

        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleTableData();
  }, []);


  const reservationDates = data.map((reservation) => reservation.Date);

  const handleChange = (newValue) => {
    setValue(newValue);
    const formattedDate = newValue.format("YYYY-MM-DD");
    props.onChildValue(formattedDate);
  };

const renderDay = ({highlightedDays = [], day, outsideCurrentMonth, ...other}) => {
  const formattedDay = dayjs(day).format("YYYY-MM-DD");
  const isReserved = reservationDates.includes(formattedDay);

    return (
      <Badge
      key={day.toString()}
      overlap="circular"
      badgeContent={isLoading ? <CircularProgress size={5}/> : isReserved ? <p style={{fontSize:'5px'}}>🔴</p> : undefined}
    >
      <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
    </Badge>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
      showDaysOutsideCurrentMonth
      // loading={isLoading}
      //  renderLoading={() => < p>loading... </p>}
        sx={{
          color: "#fff",
          fontSize: "12px",

          "&.MuiDateCalendar-root": {
            fontFamily: "outfit",
            fontWeight: 600,
            fontSize: "10px",
          },
        }}
        disablePast={props.past}
        disableFuture={props.future}
        value={value}
        onChange={(newValue) => handleChange(newValue)}
        slots={{
          day: renderDay,
        }}
        slotProps={{

        }}
      />
    </LocalizationProvider>
  );
};

export default Calender;
