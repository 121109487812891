import {
  Button,
  Box,
  Typography,

} from "@mui/material";
import React, { useState } from "react";

import { format, parseISO } from "date-fns";
import { addSpecialOccasion,  } from "../../../axios/api";
import Loader from "../Common/Loader";
import { useSnackbar } from "notistack";

import CustomModal from "../Common/CustomModal";

import Calender from "../Common/Calender";

const CreateFullyBooked = ({ action }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const dateObject = new Date();
  const toDate = dateObject.toISOString().split("T")[0];
  const [value, setValue] = React.useState(toDate);
  const parsedDate = parseISO(value);
  const formattedDate1 = format(parsedDate, "EEEE, MMMM d");

  const handleAlert = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };


  const handleCreateReservationHour = async () => {
    setIsLoading(true);
    await addSpecialOccasion(null, value, null, null, true)
      .then((res) => {
        setIsLoading(false);
        if (res.data.status) {
          handleAlert("success", `Fully booked created for ${formattedDate1}`);
          handleCloseModal();
          action();
        } else {
          handleAlert("error", `${res?.data.error_message}`);
        }
      })
      .catch((err) => {
        setIsLoading(false);

        handleAlert("error", `${err}`);
      });
  };

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const resWidth = {
    lg: 500,
    md: 500,
    sm: 400,
    xs: 350,
  };
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenModal}
        sx={{ py: 2, px: 4, borderRadius: "10px" }}
      >
        Fully Booked
      </Button>

      <CustomModal
        open={openModal}
        onClose={handleCloseModal}
        title=" Fully Booked"
        width={resWidth}
      >
        <Box>
          {isLoading && <Loader />}

          <Box sx={{ mt: 2, bgcolor: "", borderRadius: 2, p: 2 }}>
            <Calender
              future={false}
              past={false}
              onChildValue={(value) => setValue(value)}
            />
            <Typography
              sx={{
                color: "#fff",
                fontSize: "20px",
                fontWeight: 700,
                textAlign:'center',
                mb: 1,
                
              }}
            >
             {formattedDate1}
            </Typography>

            <Box
              sx={{
                mt: 3,
                alignItems: "center",
              }}
            >
              <Box align="right" sx={{ mt: 4 }}>
                <Button
                  // disabled={}
                  variant="contained"
                  fullWidth
                  sx={{
                    py: 2,
                    px: 4,
                    borderRadius: "10px",
                    textTransform: "initial",
                    fontFamily: "outfit",
                    fontWeight: 500,
                  }}
                  onClick={() => {
                    handleCreateReservationHour();
                  }}
                >
                  Create
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </CustomModal>
    </>
  );
};

export default CreateFullyBooked;
