import React, { useEffect, useState } from "react";
import dataSet from "../../AppWidget/Chart/data";
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  MenuItem,
  InputAdornment,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { CgArrowLongLeft } from "react-icons/cg";
import { Link } from "react-router-dom";
import { GoDotFill } from "react-icons/go";
import ReGraph from "../../AppWidget/Chart/ReGraph";
import searchIcon from "../../../../assets/Icons/search.svg";
import { getEvent, getExperienceReport, getExperienceSold } from "../../../../axios/api";
import Loader from "../../Common/Loader";

const AdvancedExperience = ({  chartData }) => {
  const [dayIntervals, setDayIntervals] = useState("");
  const [values, setValues] = useState(null)

  const [reports, setReports] = useState(null)

  const handleUpdateDayInterval = (value) => {
    setDayIntervals(value);
  };

    const [selectedEvent, setSelectedEvent] = useState(null)

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [dayInterval, setDayInterval] = useState(7);

  const [isLoading, setIsLoading] = useState(false);

  const handleGetExperienceReport = async () => {
    setIsLoading(true);
    await getExperienceReport(dayInterval)
      .then((res) => {
 
        if(res?.data?.status){
         setValues(res?.data?.result)
        }
      })
      .catch((err) => {
      
    
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleGetExperienceSold = async () => {
  if(selectedEvent){
     setIsLoading(true);
    await getExperienceSold(selectedEvent?.EventId, dayInterval)
      .then((res) => {
 
        if(res?.data?.status){
        }
      })
      .catch((err) => {
      
    
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  };

  const [events, setEvents] = useState(null)

  
  const handleGetEvent = async () => {
    setIsLoading(true);
    await getEvent()
      .then((res) => {
        if(res?.data?.status){
         setEvents(res?.data?.result)
         setSelectedEvent(res?.data?.result[0])
        }
      })
      .catch((err) => {

      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(()=>{
   handleGetEvent()
  }, [])



  useEffect(() => {
    handleGetExperienceReport();
    handleUpdateDayInterval(dayInterval);
    handleGetExperienceSold()
  }, [dayInterval, selectedEvent]);



  return (
    <>
      {isLoading && <Loader />}
      <Box
        sx={{
          bgcolor: "#1a1a1a",
          border: "0.5px solid #dedede",
          py: 1,
          px: 4,
          borderLeft: "0px",
        }}
      >
        <Link to={`/dashboard/advanced-reports`}>
          <Button startIcon={<CgArrowLongLeft style={{ color: "#fff" }} />}>
            {" "}
            <Typography
              sx={{ color: "#fff", fontWeight: 500, fontFamily: "optima" }}
            >
              Advanced Reports
            </Typography>
          </Button>
        </Link>
      </Box>

      <Box
        sx={{
          my: 3,
          px: 4,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography>Experiences</Typography>
        <Button variant="contained">Export as CSV</Button>
      </Box>
      <Box sx={{ px: 4 }}>
        <Grid container spacing={2}>
          <Grid item md={4}>
            <Box
              sx={{
                bgcolor: "background.paper",
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
                p: 3,
                borderRadius: "10px",
                height: "135px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                boxSizing: "border-box",
              }}
            >
              <Box>
                <Typography sx={{ fontSize: "13px", fontWeight: 400 }}>
                  Total experiences
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "optima",
                    fontSize: "16px",
                    fontWeight: 400,
                    mt: 1,
                  }}
                >
                   {values?.TotalExperience?.toLocaleString()}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box
              sx={{
                bgcolor: "background.paper",
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
                p: 3,
                borderRadius: "10px",
                height: "135px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                boxSizing: "border-box",
              }}
            >
              <Box>
                <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                  Total tickets sold
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "optima",
                    fontSize: "16px",
                    fontWeight: 400,
                    mt: 1,
                  }}
                >
                  {values?.TotalTicketSold}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box
              sx={{
                bgcolor: "background.paper",
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
                p: 3,
                borderRadius: "10px",
                height: "135px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                boxSizing: "border-box",
              }}
            >
              <Box>
                <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                  Total party size
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "optima",
                    fontSize: "16px",
                    fontWeight: 400,
                    mt: 1,
                  }}
                >
                  {values?.TotalPartySize}
                </Typography>
              </Box>
            </Box>
          </Grid>
          {/* <Grid item md={12}>
            <Box sx={{ p: 3, bgcolor: "#1a1a1a", borderRadius: "10px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography sx={{ color: "#fff" }}>
                  Reservations & Walk-ins Overview
                </Typography>
                <DropdownSelector fetchCustomData={fetchCustomData} />
              </Box>
              <Box sx={{ mt: 2 }}>
                <ReGraph2 data={data2} />
              </Box>
            </Box>
          </Grid> */}

          <Grid item md={12}>
            <Box sx={{ p: 3, bgcolor: "#1a1a1a", borderRadius: "10px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography sx={{ fontSize: "13px", color: "#fff" }}>
                  Tickets sold
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 2,
                    width: "40%",
                  }}
                >
                  <Box sx={{ width: "50%" }}>
                    <TextField
                      sx={{
                        bgcolor: "#333",
                        borderRadius: "8px",
                        fontSize: "12px",
                      }}
                      placeholder="Select Events"
                      select
                      size="small"
                      fullWidth
                      value={selectedEvent}
                      InputProps={{
                        style: {
                          borderRadius: "8px",
                          fontSize: "12px",
                        },
                      }}
                    >
                     {
                    events?.map((event, index)=>(
                           <MenuItem
                           key={index}
                        value={event?.EventName}
                        sx={{ fontSize: "12px", py: 1 }}
                        onClick={() => setSelectedEvent(event)}
                      >
                      {event?.EventName}
                      </MenuItem>
                    ))
                     }
                    </TextField>
                  </Box>
                  <Box sx={{ width: "50%" }}>
                    <TextField
                      variant="outlined"
                      sx={{ bgcolor: "#333", borderRadius: "8px" }}
                      select
                      value={dayInterval}
                      size="small"
                      fullWidth
                      InputProps={{
                        style: {
                          borderRadius: "8px",
                          fontSize: "10px",
                        },
                      }}
                    >
                      <MenuItem
                        value={7}
                        sx={{ fontSize: "12px", py: 1 }}
                        onClick={() => setDayInterval(7)}
                      >
                        Last_7_days
                      </MenuItem>
                      <MenuItem
                        value={14}
                        sx={{ fontSize: "12px", py: 1 }}
                        onClick={() => setDayInterval(14)}
                      >
                        Last_14_days
                      </MenuItem>
                      <MenuItem
                        value={30}
                        sx={{ fontSize: "12px", py: 1 }}
                        onClick={() => setDayInterval(30)}
                      >
                        {" "}
                        Last_30_days
                      </MenuItem>
                      <MenuItem
                        value={90}
                        sx={{ fontSize: "12px", py: 1 }}
                        onClick={() => setDayInterval(90)}
                      >
                        Last_90_days
                      </MenuItem>
                    </TextField>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ mt: 2 }}>
                <ReGraph data={chartData} />
              </Box>
            </Box>
          </Grid>
          <Grid item md={12}>
            <Box
              sx={{
                bgcolor: "background.paper",
                borderRadius: "10px",
                p: 3,
                boxSizing: "border-box",
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
                  Experiences Overview
                </Typography>
                <Box
                  sx={{ display: "flex", alignItems: "center", columnGap: 2 }}
                >
                  <TextField
                    size="small"
                    sx={{ width: "300px" }}
                    placeholder="Search experiences"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment>
                          <img
                            src={searchIcon}
                            style={{ marginRight: "8px" }}
                          />
                        </InputAdornment>
                      ),
                      style: {
                        borderRadius: "8px",

                        fontSize: "12px",
                      },
                    }}
                  />
                  {/* <TextField select placeholder="Samm">
                    <MenuItem>Sammuek</MenuItem>
                    <MenuItem>Sammuek</MenuItem>
                    <MenuItem>Sammuek</MenuItem>
                    <MenuItem>Sammuek</MenuItem>
                  </TextField> */}
                </Box>
              </Box>
              {/* <Table sx={{ mt: 2 }}>
                <TableHead sx={{ bgcolor: "#262626", borderRadius: "5px" }}>
                  <TableRow>
                    <TableCell sx={{ color: "#ccc" }}>Date</TableCell>
                    <TableCell>Name of experience</TableCell>
                    <TableCell>No of Tickets sold</TableCell>
                    <TableCell>Amount Made</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow sx={{ bgcolor: "#333" }}>
                    <TableCell sx={{ color: "#ccc", fontSize: "12px" }}>
                      4/12/2023
                    </TableCell>
                    <TableCell sx={{ color: "#ccc", fontSize: "12px" }}>
                      Bistro at the Theatre
                    </TableCell>
                    <TableCell sx={{ color: "#ccc", fontSize: "12px" }}>
                      200
                    </TableCell>
                    <TableCell sx={{ color: "#ccc", fontSize: "12px" }}>
                      ₦1,154,400
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ bgcolor: "#333" }}>
                    <TableCell sx={{ color: "#ccc", fontSize: "12px" }}>
                      4/12/2023
                    </TableCell>
                    <TableCell sx={{ color: "#ccc", fontSize: "12px" }}>
                      Bistro at the Theatre
                    </TableCell>
                    <TableCell sx={{ color: "#ccc", fontSize: "12px" }}>
                      200
                    </TableCell>
                    <TableCell sx={{ color: "#ccc", fontSize: "12px" }}>
                      ₦1,154,400
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ bgcolor: "#333" }}>
                    <TableCell sx={{ color: "#ccc", fontSize: "12px" }}>
                      4/12/2023
                    </TableCell>
                    <TableCell sx={{ color: "#ccc", fontSize: "12px" }}>
                      Bistro at the Theatre
                    </TableCell>
                    <TableCell sx={{ color: "#ccc", fontSize: "12px" }}>
                      200
                    </TableCell>
                    <TableCell sx={{ color: "#ccc", fontSize: "12px" }}>
                      ₦1,154,400
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ bgcolor: "#333" }}>
                    <TableCell sx={{ color: "#ccc", fontSize: "12px" }}>
                      4/12/2023
                    </TableCell>
                    <TableCell sx={{ color: "#ccc", fontSize: "12px" }}>
                      Bistro at the Theatre
                    </TableCell>
                    <TableCell sx={{ color: "#ccc", fontSize: "12px" }}>
                      200
                    </TableCell>
                    <TableCell sx={{ color: "#ccc", fontSize: "12px" }}>
                      ₦1,154,400
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table> */}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AdvancedExperience;
