import {
  Box,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Grid,
  Button,
  IconButton,
  Modal,
  useTheme,
  useMediaQuery,
  InputLabel,
  Divider,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import CustomInput from "../../Components/CustomField/CustomInput";
import deleteIcon from "../../../assets/Icons/delete.svg";
import CreateReservationHour from "../../Components/Reservations/CreateReservationHour";
import CreateSpecialOccasion from "../../Components/Reservations/CreateSpecialOccasion";
import {
  deleteReservationHours,
  deleteSpecialHour,
  getAllSpecialOccasion,
  getReservationHours,
} from "../../../axios/api";
import { useScroll } from "@react-spring/web";
import Loader from "../../Components/Common/Loader";
import { useSnackbar } from "notistack";
import { Helmet } from "react-helmet-async";
import pen from "../../../assets/Icons/pen.svg";
import bin from "../../../assets/Icons/bin.svg";
import { CloseOutlined } from "@mui/icons-material";
import EditHour from "../../Components/Reservations/EditHour";
import EditOccasion from "../../Components/Reservations/EditOccasion";
import anime from "../../../assets/anime/KY0fkZoRyj.json";
import Lottie from "react-lottie";
import NoMobile from "../NoMobile";
import SEO from "../../../Utility/SEO";
import Header from "../../Components/Header";
import CreateFullyBooked from "../../Components/Reservations/CreateFullyBooked";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 500, md: 500, sm: 400, xs: 350 },
  bgcolor: "background.paper",
borderRadius:'10px',
  boxShadow: 24,
};

const ReservationHours = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const [editItem, setEditItem] = useState(null);
  const [isOccasion, setIsOccasion] = useState(false);
  const handleOpen = (item, event) => {
    setOpen(true);
    setEditItem(item);
    setIsOccasion(event);
  };
  const handleClose = () => setOpen(false);
  const [isLoading, setIsLoading] = useState(false);

  const [hoursData, setHoursData] = useState(null);
  const [specialData, setSpecialData] = useState(null);

  const handleAlert = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };

  const handleGetReservationHours = async () => {
    setIsLoading(true);
    await getReservationHours()
      .then((res) => {
        setIsLoading(false);
        const { data } = res;
        if (data.status) {
          setHoursData(data?.result);
        }
      })
      .catch((err) => {
        handleAlert(`${err?.message}`);
        setIsLoading(false);
      });
  };
  const handleGetSpecialHours = async () => {
    setIsLoading(true);
    await getAllSpecialOccasion()
      .then((res) => {
        setIsLoading(false);
        const { data } = res;
        if (data.status) {
          setSpecialData(data?.result);

        }
      })
      .catch((err) => {
        handleAlert(`${err?.message}`);
        setIsLoading(false);
      });
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: anime,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    handleGetReservationHours();
    handleGetSpecialHours();
  }, []);

  const handleDeleteReservationHours = async (id) => {
    setIsLoading(true);
    await deleteReservationHours(id)
      .then((res) => {
        setIsLoading(false);

        if (res.data.status) {
          handleAlert("success", `${res.data.success_message}`);
          handleGetReservationHours();
          handleClose();
        } else {
          handleAlert("error", `${res.data.error_message}`);
        }
      })
      .catch((err) => {
        handleAlert("error", `${err.message}`);
        setIsLoading(false);
      });
  };
  const handleDeleteSpecialHours = async (id) => {
    setIsLoading(true);
    await deleteSpecialHour(id)
      .then((res) => {
        setIsLoading(false);

        if (res.data.status) {
          handleAlert("success", `${res.data.success_message}`);
          handleGetSpecialHours();
          handleClose();
        } else {
          handleAlert("error", `${res.data.error_message}`);
        }
      })
      .catch((err) => {
        handleAlert("error", `${err.message}`);
        setIsLoading(false);
      });
  };

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <SEO
        title="Reisty | Restaurant Reservation Hours Management"
        description="Enhance your restaurant management with Reisty’s intuitive dashboard and real-time insights."
        name="Reisty"
        type="article"
          canonicalUrl="https://restaurant.reisty.com/dashboard/reservation-hours"
      />
      {isMobile ? (
        <>
          <NoMobile />
        </>
      ) : (
        <>
          {isLoading && <Loader />}
        
          <Header title="Reservation Hours" description="Set and manage the time slots available for customer reservations."/>

          <Box sx={{ p: 2 }}>
            <Typography
              sx={{
           
                fontWeight: 500,
                fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
              }}
            >
              Inform your guests about your availability.
            </Typography>
            <Typography
              sx={{
           
                fontWeight: 400,
                fontSize: { lg: "12px", md: "12px", sm: "12px", xs: "10px" },
                mt: 1,
              }}
            >
              Tell guests when you are open
            </Typography>

            <Grid container spacing={2} sx={{ mt: 3 }}>
              {!hoursData || !specialData ? (
                <></>
              ) : (
                hoursData && (
                  <>
                    <Grid item lg={11} md={12} sm={12} xs={12}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                     
                          fontWeight: 500,
                        }}
                      >
                        Reservation Hours
                      </Typography>
                      {hoursData.map((item, index) => (
                        <>
                          <Box
                            sx={{
                              mt: 4,
                             bgcolor: "background.card", 
                              borderRadius: 2,
                              p: 2,
                              boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
                            }}
                          >
                            <Box
                              sx={{
                                px: 0,
                                display: "flex",
                                columnGap: 3,
                                flexDirection: {
                                  lg: "row",
                                  md: "row",
                                  sm: "column",
                                  xs: "column",
                                },
                              }}
                            >
                              <Typography
                                sx={{
                             
                                  fontSize: {
                                    lg: "14px",
                                    md: "14px",
                                    sm: "12px",
                                    xs: "12px",
                                  },
                                  fontWeight: 500,
                                }}
                              >
                                Opening days:
                              </Typography>
                              <Box>
                                <Typography
                                  sx={{
                               
                                    fontSize: {
                                      lg: "13px",
                                      md: "13px",
                                      sm: "12px",
                                      xs: "12px",
                                    },
                                    fontWeight: 500,
                                  }}
                                >
                                  {item?.Days}
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                columnGap: 3,
                                mt: { lg: 6, md: 6, sm: 3, xs: 1 },
                                alignItems: "center",
                                flexDirection: {
                                  lg: "row",
                                  md: "row",
                                  sm: "column",
                                  xs: "column",
                                },
                                width: "100%",
                                rowGap: 2,
                              }}
                            >
                              <Typography
                                sx={{
                             
                                  fontSize: {
                                    lg: "14px",
                                    md: "14px",
                                    sm: "12px",
                                    xs: "12px",
                                  },
                                  fontWeight: 500,
                                }}
                              >
                                Opening Hours :
                              </Typography>
                              <Box sx={{ flex: 1 }}>
                                <Grid
                                  container
                                  rowGap={2}
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Grid
                                    align="center"
                                    item
                                    lg={3}
                                    md={4}
                                    sm={12}
                                    xs={12}
                                  >
                                    <Box
                                      sx={{
                                        borderRadius: 2,
                                        border: "1px solid #fff",
                                        p: 1,
                                        px:2,
                                        width: "fit-content",
                                   
                                        bgcolor: "#3333",
                                        fontSize: {
                                          lg: "12px",
                                          md: "12px",
                                          sm: "12px",
                                          xs: "12px",
                                        },
                                        boxSizing:'border-box'
                                      }}
                                    >
                                      <InputLabel>

                                      {item?.MealType}
                                      </InputLabel>
                                    </Box>
                                  </Grid>
                                  <Grid item lg={6} md={6} sm={9} xs={9}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        columnGap: 2,
                                      }}
                                    >
                                      <Typography
                                        sx={{ fontSize: "10px", }}
                                      >
                                        From:
                                      </Typography>
                                      <Box
                                        sx={{
                                          borderRadius: 2,
                                          border: "1px solid #fff",
                                          p: 1,
                                          px: 4,
                                          width: "fit-content",
                                     
                                          bgcolor: "#3333",
                                          fontSize: {
                                            lg: "12px",
                                            md: "12px",
                                            sm: "12px",
                                            xs: "10px",
                                          },
                                        }}
                                      >
                                        {item?.From}
                                      </Box>

                                      <Typography
                                        sx={{ fontSize: "10px", }}
                                      >
                                        To:
                                      </Typography>
                                      <Box
                                        sx={{
                                          borderRadius: 2,
                                          border: "1px solid #fff",
                                          p: 1,
                                          px: 4,
                                          width: "fit-content",
                                     
                                          bgcolor: "#3333",
                                          fontSize: {
                                            lg: "12px",
                                            md: "12px",
                                            sm: "12px",
                                            xs: "10px",
                                          },
                                        }}
                                      >
                                        {item?.To}
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item lg={3} md={2} xs={3} sm={3}>
                                    <Box align="right">
                                      <EditHour
                                        data={item}
                                        action={handleGetReservationHours}
                                      />
                                      <IconButton
                                        onClick={() => handleOpen(item, false)}
                                        sx={{bgcolor:'#333', ml:1}}
                                      >
                                        <img src={bin} alt="icon" width={20} />
                                      </IconButton>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          </Box>
                        </>
                      ))}
                    </Grid>

                    <Grid item lg={8} md={12} sm={12} xs={12} sx={{ mt: 3 }}>

                    <Divider sx={{mb:3}}/>
                      <Typography
                        sx={{
                          fontSize: "15px",
                     
                          fontWeight: 500,
                        }}
                      >
                        Special Occasion
                      </Typography>
                      {specialData.map((item, index) => (
                        <>
                          <Box
                            sx={{
                              mt: 2,
                              bgcolor: "#1a1a1a",
                              borderRadius: 2,
                              p: 2,
                              ...(item?.IsFullyBooked === true) && {
                                display:'none'
                              }
                            }}
                          >
                            <Box
                              sx={{
                                px: 0,
                                display: "flex",
                                columnGap: 3,
                                flexDirection: {
                                  lg: "row",
                                  md: "row",
                                  sm: "column",
                                  xs: "column",
                                },
                              }}
                            >
                              <Typography
                                sx={{
                             
                                  fontSize: {
                                    lg: "14px",
                                    md: "14px",
                                    sm: "12px",
                                    xs: "12px",
                                  },
                                  fontWeight: 500,
                                }}
                              >
                                Event Name:
                              </Typography>
                              <Box>
                                <Typography
                                  sx={{
                               
                                    fontSize: {
                                      lg: "13px",
                                      md: "13px",
                                      sm: "12px",
                                      xs: "12px",
                                    },
                                    fontWeight: 500,
                                  }}
                                >
                                  {item?.EventName}
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                px: 0,
                                display: "flex",
                                columnGap: 3,
                                flexDirection: {
                                  lg: "row",
                                  md: "row",
                                  sm: "column",
                                  xs: "column",
                                },
                                mt: 2,
                              }}
                            >
                              <Typography
                                sx={{
                             
                                  fontSize: {
                                    lg: "14px",
                                    md: "14px",
                                    sm: "12px",
                                    xs: "12px",
                                  },
                                  fontWeight: 500,
                                }}
                              >
                                Event Date:
                              </Typography>
                              <Box>
                                <Typography
                                  sx={{
                               
                                    fontSize: {
                                      lg: "13px",
                                      md: "13px",
                                      sm: "12px",
                                      xs: "12px",
                                    },
                                    fontWeight: 500,
                                  }}
                                >
                                 {item?.EventDate && FormatDate(item?.EventDate)}
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                columnGap: 3,
                                mt: { lg: 4, md: 4, sm: 3, xs: 1 },
                                alignItems: "center",
                                flexDirection: {
                                  lg: "row",
                                  md: "row",
                                  sm: "column",
                                  xs: "column",
                                },
                                width: "100%",
                                rowGap: 2,
                              }}
                            >
                              <Typography
                                sx={{
                             
                                  fontSize: {
                                    lg: "14px",
                                    md: "14px",
                                    sm: "12px",
                                    xs: "12px",
                                  },
                                  fontWeight: 500,
                                }}
                              >
                                Opening Hours :
                              </Typography>
                              <Box sx={{ flex: 1 }}>
                                <Grid
                                  container
                                  rowGap={2}
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Grid item lg={9} md={9} sm={7} xs={7}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        columnGap: 2,
                                      }}
                                    >
                                      <Typography
                                        sx={{ fontSize: "10px", color: "#fff" }}
                                      >
                                        From:
                                      </Typography>
                                      <Box
                                        sx={{
                                          borderRadius: 2,
                                          border: "1px solid #fff",
                                          p: 1,
                                          px: 4,
                                          width: "fit-content",
                                     
                                          bgcolor: "#3333",
                                          fontSize: {
                                            lg: "12px",
                                            md: "12px",
                                            sm: "12px",
                                            xs: "10px",
                                          },
                                        }}
                                      >
                                        {item?.EventTimeFrom}
                                      </Box>

                                      <Typography
                                        sx={{ fontSize: "10px", color: "#fff" }}
                                      >
                                        To:
                                      </Typography>
                                      <Box
                                        sx={{
                                          borderRadius: 2,
                                          border: "1px solid #fff",
                                          p: 1,
                                          px: 4,
                                          width: "fit-content",
                                     
                                          bgcolor: "#3333",
                                          fontSize: {
                                            lg: "12px",
                                            md: "12px",
                                            sm: "12px",
                                            xs: "10px",
                                          },
                                        }}
                                      >
                                        {item?.EventTimeTo}
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item lg={3} md={2} xs={5} sm={5}>
                                    <Box align="right">
                                      <EditOccasion
                                        action={handleGetSpecialHours}
                                        data={item}
                                      />
                                      <IconButton
                                        onClick={() => handleOpen(item, true)}
                                      >
                                        <img src={bin} alt="icon" />
                                      </IconButton>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          </Box>
                        </>
                      ))}
                    </Grid>
                    <Grid item lg={8} md={12} sm={12} xs={12} sx={{ mt: 3 }}>

                    <Divider sx={{mb:3}}/>
                      <Typography
                        sx={{
                          fontSize: "15px",
                     
                          fontWeight: 500,
                        }}
                      >
                    Fully Booked
                      </Typography>
                      {specialData.map((item, index) => (
                        <>
                          <Box
                            sx={{
                              mt: 2,
                              bgcolor: "#1a1a1a",
                              borderRadius: 2,
                              p: 2,
                              ...(item?.IsFullyBooked === false) && {
                                display:'none'
                              }
                            }}
                          >
                           
                            <Box
                              sx={{
                                px: 0,
                                display: "flex",
                                columnGap: 3,
                                flexDirection: {
                                  lg: "row",
                                  md: "row",
                                  sm: "column",
                                  xs: "column",
                                },
                                mt: 2,
                              }}
                            >
                              <Typography
                                sx={{
                             
                                  fontSize: {
                                    lg: "14px",
                                    md: "14px",
                                    sm: "12px",
                                    xs: "12px",
                                  },
                                  fontWeight: 500,
                                }}
                              >
                               Date:
                              </Typography>
                              <Box>
                                <Typography
                                  sx={{
                               
                                    fontSize: {
                                      lg: "13px",
                                      md: "13px",
                                      sm: "12px",
                                      xs: "12px",
                                    },
                                    fontWeight: 500,
                                  }}
                                >
                                  {item?.EventDate && FormatDate(item?.EventDate)}
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                columnGap: 3,
                                mt: { lg: 4, md: 4, sm: 3, xs: 1 },
                                alignItems: "center",
                                flexDirection: {
                                  lg: "row",
                                  md: "row",
                                  sm: "column",
                                  xs: "column",
                                },
                                width: "100%",
                                rowGap: 2,
                              }}
                            >
                              <Typography
                                sx={{
                             
                                  fontSize: {
                                    lg: "14px",
                                    md: "14px",
                                    sm: "12px",
                                    xs: "12px",
                                  },
                                  fontWeight: 500,
                                }}
                              >
                                Fully Booked  :
                              </Typography>
                              <Box sx={{ flex: 1 }}>
                                <Grid
                                  container
                                  rowGap={2}
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Grid item lg={9} md={9} sm={7} xs={7}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        columnGap: 2,
                                      }}
                                    >
                                      <Typography
                                        sx={{ fontSize: "10px", color: "#fff" }}
                                      >
                                        From:
                                      </Typography>
                                      <Box
                                        sx={{
                                          borderRadius: 2,
                                          border: "1px solid #fff",
                                          p: 1,
                                          px: 4,
                                          width: "fit-content",
                                     
                                          bgcolor: "#3333",
                                          fontSize: {
                                            lg: "12px",
                                            md: "12px",
                                            sm: "12px",
                                            xs: "10px",
                                          },
                                        }}
                                      >
                                        {item?.EventTimeFrom}
                                      </Box>

                                      <Typography
                                        sx={{ fontSize: "10px", color: "#fff" }}
                                      >
                                        To:
                                      </Typography>
                                      <Box
                                        sx={{
                                          borderRadius: 2,
                                          border: "1px solid #fff",
                                          p: 1,
                                          px: 4,
                                          width: "fit-content",
                                     
                                          bgcolor: "#3333",
                                          fontSize: {
                                            lg: "12px",
                                            md: "12px",
                                            sm: "12px",
                                            xs: "10px",
                                          },
                                        }}
                                      >
                                        {item?.EventTimeTo}
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid item lg={3} md={2} xs={5} sm={5}>
                                    <Box align="right">
                                
                                      <IconButton
                                        onClick={() => handleOpen(item, true)}
                                      >
                                        <img src={bin} alt="icon" />
                                      </IconButton>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          </Box>
                        </>
                      ))}
                    </Grid>
                  </>
                )
              )}
            </Grid>
            <Box
              sx={{
                mt: 7,
                display: "flex",
                alignItems: "center",
                columnGap: 2,
              }}
            >
              <CreateReservationHour action={handleGetReservationHours} />
              <CreateSpecialOccasion action={handleGetSpecialHours} />
              <CreateFullyBooked action={handleGetSpecialHours}/>
            </Box>
          </Box>
        </>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              bgcolor: "background.paper",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 2,
              pb:3,
              borderRadius:'10px'
            }}
          >
            <Box sx={{ width: "30%" }}>
              <Lottie options={defaultOptions} width={100} height={100} />
            </Box>
            <Box sx={{ width: "70%" }}>
              <Typography
                sx={{  fontWeight: 600, fontSize: "16px" }}
              >
                {" "}
                Are you sure you want to delete this settings?
              </Typography>
              <Typography
                sx={{  fontSize: "12px", lineHeight: "20px" }}
              >
                This settings will be deleted immediately, you can’t undo this
                action.
              </Typography>

              <Box sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  onClick={handleClose}
                  sx={{ borderRadius: "8px", px: 4 }}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  sx={{ borderRadius: "8px", px: 4, ml: 2 }}
                  onClick={() =>
                    isOccasion
                      ? handleDeleteSpecialHours(editItem?.Id)
                      : handleDeleteReservationHours(editItem?.Id)
                  }
                >
                  Delete
                </Button>
              </Box>
            </Box>
          </Box>
    
        </Box>
      </Modal>
    </>
  );
};

export default ReservationHours;


const FormatDate = (value) =>{
    const parsedDate = parseISO(value);
  const formattedDate1 = format(parsedDate, "EEEE, MMMM d")
  return formattedDate1
}
