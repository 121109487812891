import {
  Box,
  Grid,
  Typography,
  Popover,
  Button,
  TextField,
  InputAdornment,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Modal,

} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import tag from "../../../assets/Icons/table/filled/tag.svg";
import clock from "../../../assets/Icons/table/filled/clock.svg";
import flag from "../../../assets/Icons/table/filled/flag.svg";
import calendarIcon from "../../../assets/Icons/calendar-tick.svg";
import { MdInfoOutline } from "react-icons/md";

import Reservation from "./Reservation";
import Calender from "../../Components/Common/Calender";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { format, parseISO } from "date-fns";
import Statistics from "./Statistics";
import emptyIcon from "../../../assets/emptyRes.svg";
import searchIcon from "../../../assets/Icons/search.svg";
import { BsBookmarkCheckFill } from "react-icons/bs";
import dataSet from "./Chart/data";

import { confirmReservation, getDashboardReviews } from "../../../axios/api";
import Pagination from "../Common/Pagination";

const style = {
  position: "absolute",
  top: "20%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  boxSizing: "border-box",
  borderRadius: "8px",
};

const GeneralOverview = ({
  data,
  handleFilter,
  handleDetailsScreen,
  values,
  handleUpdateDate,
  tableData,
  action
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dateObject = new Date();
  const toDate = dateObject.toISOString().split("T")[0];
  const [value, setValue] = React.useState(toDate);
  const [isLoading, setIsLoading] = useState(false);

  const parsedDate = parseISO(value);
  const formattedDate1 = format(parsedDate, "EEEE, MMMM d");
  const date = new Date();
  const formatDate = () => {
    const options = { weekday: "long", month: "long", day: "numeric" };

    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    handleUpdateDate(value);
  }, [value]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [data2, setData2] = useState(dataSet.Today);

  const fetchCustomData = (key) => {
    setData2(dataSet[key]);
  };

  const [reviews, setReviews] = useState(["sam"]);

  const handleGetReviews = async () => {
    setIsLoading(true);
    await getDashboardReviews()
      .then((res) => {
        if (res?.data?.status) {
          setReviews(res?.data?.result);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleGetReviews();
  }, []);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (rows) => {
    setRowsPerPage(rows);
    setPage(0);
  };



  const { enqueueSnackbar } = useSnackbar();
  const handleAlert = (variant, message) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };

  const [open3, setOpen3] = useState(false);

  const handleApproveReservation = async () => {
    setIsLoading(true);
    await confirmReservation(singleReservation?.ReservationId)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status) {
          handleAlert(
            "success",
            `${
              res?.data?.success_message || "Reservation successfully approved"
            }`
          );
           action()
          setOpen3(false);
        } else {
          handleAlert("error", `${res?.data?.error_message}`);
        }
      })
      .catch((err) => {
        handleAlert("error", `${err.message}`);
      });
  };

  const restaurantName = localStorage.getItem("restaurantName");

  const [singleReservation, setSingleReservation] = useState(null);

  const isNostalgia = restaurantName === "NOSTALGIA LAGOS";

  const today = new Date().toISOString().split("T")[0]; 


  const sortedReservations = [...tableData || []].sort((a, b) => {
    const dateTimeA = new Date(`${a.Date}T${a.Time}`);
    const dateTimeB = new Date(`${b.Date}T${b.Time}`);
    const isTodayA = a.Date === today;
    const isTodayB = b.Date === today;

    // Group by today, past, or future
    if (isTodayA && !isTodayB) return -1; // A is today
    if (!isTodayA && isTodayB) return 1;  // B is today
    if (a.Date < today && b.Date >= today) return 1; // A is past, B is today or future
    if (a.Date >= today && b.Date < today) return -1; // A is today or future, B is past

    // Sort within groups: descending for past, ascending for future
    return a.Date < today ? dateTimeB - dateTimeA : dateTimeA - dateTimeB;
  });


  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item md={4} sm={6} xs={12}>
            <Box
              onClick={() => {
                if (values?.TodayOngoingReservations?.length > 0) {
                  handleDetailsScreen(values?.TodayOngoingReservations);
                }
              }}
              sx={{
                bgcolor: "#19A662",
                p: 3,
                cursor:
                  values?.TodayOngoingReservations?.length > 0
                    ? "pointer"
                    : "not-allowed",
                borderRadius: "10px",
                height: "135px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                boxSizing: "border-box",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: 500,
                    fontFamily: "optima",
                  }}
                >
                  Ongoing Reservations
                </Typography>
                {!values?.TodayOngoingReservations ||
                values?.TodayOngoingReservations?.length === 0 ? (
                  <>
                    <Typography sx={{ color: "#ccc", fontSize: "12px" }}>
                      No ongoing reservation available
                    </Typography>
                  </>
                ) : (
                  <Box sx={{ mt: 1 }}>
                    <Box sx={{ display: "flex", columnGap: 1, mt: 1 }}>
                      <img src={tag} width={13} />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "10px",
                          fontWeight: 400,
                        }}
                      >
                        {
                          values?.TodayOngoingReservations[0]
                            ?.bookingUserDetails?.Name
                        }
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", columnGap: 1, mt: 1 }}>
                      <img src={flag} width={12} />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "10px",
                          fontWeight: 400,
                        }}
                      >
                        {values?.TodayOngoingReservations[0]?.TableName}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", columnGap: 1, mt: 1 }}>
                      <img src={clock} width={12} />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "10px",
                          fontWeight: 400,
                        }}
                      >
                        {values?.TodayOngoingReservations[0]?.BookingTime}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Box
              onClick={() => {
                if (values?.TodayUpcomingReservations?.length > 0) {
                  handleDetailsScreen(values?.TodayUpcomingReservations);
                }
              }}
              sx={{
                bgcolor: "#BC172F",
                p: 3,
                cursor:
                  values?.TodayUpcomingReservations?.length > 0
                    ? "pointer"
                    : "not-allowed",
                borderRadius: "10px",
                height: "135px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                boxSizing: "border-box",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: 500,
                    fontFamily: "optima",
                  }}
                >
                  Upcoming Reservations
                </Typography>
                {!values?.TodayUpcomingReservations ||
                values?.TodayUpcomingReservations?.length === 0 ? (
                  <>
                    <Typography sx={{ color: "#ccc", fontSize: "12px" }}>
                      No upcoming reservation available
                    </Typography>
                  </>
                ) : (
                  <Box sx={{ mt: 1 }}>
                    <Box sx={{ display: "flex", columnGap: 1, mt: 1 }}>
                      <img src={tag} width={13} />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "10px",
                          fontWeight: 400,
                        }}
                      >
                        {
                          values?.TodayUpcomingReservations[0]
                            ?.bookingUserDetails?.Name
                        }
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", columnGap: 1, mt: 1 }}>
                      <img src={flag} width={12} />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "10px",
                          fontWeight: 400,
                        }}
                      >
                        {values?.TodayUpcomingReservations[0]?.TableName}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", columnGap: 1, mt: 1 }}>
                      <img src={clock} width={12} />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "10px",
                          fontWeight: 400,
                        }}
                      >
                        {values?.TodayUpcomingReservations[0]?.BookingTime}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Box
              onClick={() => {
                if (values?.TodayFinishedReservations?.length > 0) {
                  handleDetailsScreen(values?.TodayFinishedReservations);
                }
              }}
              sx={{
                bgcolor: "#4787E6",
                p: 3,
                cursor:
                  values?.TodayFinishedReservations?.length > 0
                    ? "pointer"
                    : "not-allowed",
                borderRadius: "10px",
                height: "135px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                boxSizing: "border-box",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: 500,
                    fontFamily: "optima",
                  }}
                >
                  Finished Reservations
                </Typography>
                {!values?.TodayFinishedReservations ||
                values?.TodayFinishedReservations?.length === 0 ? (
                  <>
                    <Typography sx={{ color: "#ccc", fontSize: "12px" }}>
                      No finished reservation available
                    </Typography>
                  </>
                ) : (
                  <Box sx={{ mt: 1 }}>
                    <Box sx={{ display: "flex", columnGap: 1, mt: 1 }}>
                      <img src={tag} width={13} />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "10px",
                          fontWeight: 400,
                        }}
                      >
                        {
                          values?.TodayFinishedReservations[0]
                            ?.bookingUserDetails?.Name
                        }
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", columnGap: 1, mt: 1 }}>
                      <img src={flag} width={12} />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "10px",
                          fontWeight: 400,
                        }}
                      >
                        {values?.TodayFinishedReservations[0]?.TableName}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", columnGap: 1, mt: 1 }}>
                      <img src={clock} width={12} />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "10px",
                          fontWeight: 400,
                        }}
                      >
                        {values?.TodayFinishedReservations[0]?.BookingTime}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>

          <Grid item lg={8} md={8} sm={12} xs={12}>
            <Box
              sx={{
                bgcolor: "background.paper",
                borderRadius: "10px",
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
                height: "400px",
              }}
            >
              <Box
                sx={{
                  p: 1,
                  bgcolor: "#BC172F",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: "10px 10px 0px 0px",
                  px: 2,
                  height: "60px",
                }}
              >
                <Typography
                  sx={{ color: "#fff", fontSize: "14px", fontFamily: "optima" }}
                >
                  Reservation Notes
                </Typography>
                {/* <Button
                  endIcon={<TiArrowRight />}
                  sx={{ color: "#fff", fontSize: "12px" }}
                >
                  See More
                </Button> */}
              </Box>
              <Box
                sx={{
                  p: 2,
                  height: "200px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {/* <Typography sx={{ fontSize: "12px", color: "#fff" }}>
                  Today
                </Typography> */}

                <img src={emptyIcon} width={100} />
                <Typography sx={{ fontSize: "10px", color: "#fff", mt: 2 }}>
                  There are no reservation notes currently
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Box
              sx={{
                bgcolor: "background.paper",
                borderRadius: "10px",
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
                height: "400px",
              }}
            >
              <Box
                sx={{
                  p: 1,
                  bgcolor: "#BC172F",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: "10px 10px 0px 0px",
                  px: 2,
                  height: "60px",
                }}
              >
                <Typography
                  sx={{ color: "#fff", fontSize: "14px", fontFamily: "optima" }}
                >
                  Reviews
                </Typography>
              </Box>
              {!reviews || reviews.length === 0 ? (
                <>
                  <Box
                    sx={{
                      p: 2,
                      height: "350px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img src={emptyIcon} width={100} />
                    <Typography sx={{ fontSize: "10px", color: "#fff", mt: 2 }}>
                      There are no reviews currently
                    </Typography>
                  </Box>
                </>
              ) : (
                <>
                  {/* <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      p: 2,
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      sx={{ fontSize: "28px", fontWeight: 500, mt: 1 }}
                    >
                      4.5
                    </Typography>
                    <Rating
                      readOnly
                      precision={0.5}
                      value={4.5}
                      sx={{ mt: 1 }}
                    />

                    <Box
                      sx={{
                        bgcolor: "#333333",
                        borderRadius: "8px",
                        p: 1.5,
                        boxSizing: "border-box",
                        width: "95%",
                        mt: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: 1,
                          }}
                        >
                          <Avatar></Avatar>
                          <Box>
                            <InputLabel sx={{ fontWeight: 600, color: "#fff" }}>
                              Modupe Asake
                            </InputLabel>
                            <Typography
                              sx={{ fontSize: "10px", color: "#717171" }}
                            >
                              December 2022
                            </Typography>
                          </Box>
                        </Box>
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              columnGap: 0.5,
                            }}
                          >
                            <IoMdStar style={{ color: "#DF8E14" }} />
                            <Typography sx={{ fontSize: "10px" }}>
                              4.5
                            </Typography>
                          </Box>
                          <Typography sx={{ fontSize: "10px" }}>
                            a month ago
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{mt:1}}>
                        <Typography sx={{fontSize:'10px', lineHeight:'15px'}}>Absolutely delightful dining experience! The food was exceptional, and the service was top-notch. We'll definitely be coming back for more.</Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        bgcolor: "#333333",
                        borderRadius: "8px",
                        p: 1.5,
                        boxSizing: "border-box",
                        width: "95%",
                        mt: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: 1,
                          }}
                        >
                          <Avatar></Avatar>
                          <Box>
                            <InputLabel sx={{ fontWeight: 600, color: "#fff" }}>
                              Modupe Asake
                            </InputLabel>
                            <Typography
                              sx={{ fontSize: "10px", color: "#717171" }}
                            >
                              December 2022
                            </Typography>
                          </Box>
                        </Box>
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              columnGap: 0.5,
                            }}
                          >
                            <IoMdStar style={{ color: "#DF8E14" }} />
                            <Typography sx={{ fontSize: "10px" }}>
                              4.5
                            </Typography>
                          </Box>
                          <Typography sx={{ fontSize: "10px" }}>
                            a month ago
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{mt:1}}>
                        <Typography sx={{fontSize:'10px', lineHeight:'15px'}}>Absolutely delightful dining experience! The food was exceptional, and the service was top-notch. We'll definitely be coming back for more.</Typography>
                      </Box>
                    </Box>
                  </Box> */}
                </>
              )}
            </Box>
          </Grid>

          <Grid item lg={8} md={8} sm={12} xs={12}>
            <Box
              sx={{
                bgcolor: "background.paper",
                height: "400px",
                borderRadius: "10px",
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
              }}
            >
              <Box sx={{ p: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{}}>Reservations</Typography>
                  <Box
                    sx={{
                      bgcolor: "#1A1A1A",
                      cursor: "pointer",
                      px: 1.5,
                      py: 0.5,
                      borderRadius: "10px",
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      border: "1px solid #fff",
                    }}
                    onClick={handleClick}
                  >
                    <Box>
                      <img src={calendarIcon} alt="calendar" width="50%" />
                    </Box>
                    <Typography
                      sx={{ color: "#fff", display: "flex", fontSize: "12px" }}
                    >
                      {formattedDate1}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", columnGap: 5 }}>
                  <Box
                    sx={{
                      width: "50%",
                      height: "300px",
                    }}
                  >
                    <Reservation Rdata={data} />
                  </Box>
                  <Box sx={{ mt: 5, width: "40%" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ fontWeight: 400 }}>Status</Typography>
                      <Typography sx={{ fontWeight: 400 }}>Cover</Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        mb: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 1,
                        }}
                      >
                        <FiberManualRecordIcon sx={{ color: "#00FF47" }} />
                        <Typography sx={{ fontSize: "11px" }}>
                          Seated
                        </Typography>
                      </Box>
                      <Typography sx={{ fontSize: "11px", mt: 0.5 }}>
                        {data?.TotaSeated}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        mb: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 1,
                        }}
                      >
                        <FiberManualRecordIcon sx={{ color: "#FFC000" }} />
                        <Typography sx={{ fontSize: "11px" }}>
                          Expected
                        </Typography>
                      </Box>
                      <Typography sx={{ fontSize: "11px", mt: 0.5 }}>
                        {data?.TotalExpected}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        mb: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 1,
                        }}
                      >
                        <FiberManualRecordIcon sx={{ color: "#06F" }} />
                        <Typography sx={{ fontSize: "11px" }}>
                          Finished
                        </Typography>
                      </Box>
                      <Typography sx={{ fontSize: "11px", mt: 0.5 }}>
                        {data?.TotalFinished}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        mb: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 1,
                        }}
                      >
                        <FiberManualRecordIcon sx={{ color: "#FF0025" }} />
                        <Typography sx={{ fontSize: "11px" }}>
                          Cancelled
                        </Typography>
                      </Box>
                      <Typography sx={{ fontSize: "11px", mt: 0.5 }}>
                        {data?.TotalCancelled}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ mt: 4, fontSize: "13px" }}>
                        Total
                      </Typography>
                      <Typography sx={{ mt: 4, fontSize: "13px" }}>
                        {data?.TotalCancelled +
                          data?.TotalFinished +
                          data?.TotalExpected +
                          data?.TotaSeated}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12} sx={{}}>
            <Box
              sx={{
                bgcolor: "background.paper",
                height: "400px",
                borderRadius: "10px",
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
              }}
            >
              <Box sx={{ p: 2 }}>
                <Typography sx={{}}>Statistics</Typography>
                <Box
                  sx={{
                    width: "100%",
                    height: "300px",
                    display: "grid",
                    placeItems: "center",
                    // border:'1px solid red'
                  }}
                >
                  <Statistics data={data} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    columnGap: 3,
                    mt: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: 1,
                    }}
                  >
                    <FiberManualRecordIcon sx={{ color: "#FF0025" }} />
                    <Typography sx={{ fontSize: "10px" }}>
                      {data?.TotalOnline} Booked Online
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: 1,
                    }}
                  >
                    <FiberManualRecordIcon sx={{ color: "#fff" }} />
                    <Typography sx={{ fontSize: "10px" }}>
                      {data?.TotalWalkIn} Walk-ins
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item md={12} sm={12} xs={12}> 
            <Box
              sx={{
                bgcolor: "background.paper",
                borderRadius: "10px",
                p: {lg:3, md:3, sm:2, xs:1},
                boxSizing: "border-box",
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "left",
                  flexDirection:{lg:'row', md:'row', sm:'column', xs:'column'},
                  rowGap:2
                }}
              >
                <Box>
                  <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
                    Upcoming Reservation
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 300,
                      fontSize: "10px",
                      color: "#ccc",
                      display: "flex",
                      alignItems: "center",
                      columnGap: 1,
                    }}
                  >
                    <MdInfoOutline />
                    Double click to view more details
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", columnGap: 2 }}
                >
                  <TextField
                    size="small"
                    sx={{ width: { md: "300px", sm: "200px", xs: "100%" } }}
                    placeholder="Search experiences"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment>
                          <img
                            src={searchIcon}
                            style={{ marginRight: "8px" }}
                          />
                        </InputAdornment>
                      ),
                      style: {
                        borderRadius: "8px",

                        fontSize: "12px",
                      },
                    }}
                  />
                  {/* <TextField select placeholder="Samm">
                    <MenuItem>Sammuek</MenuItem>
                    <MenuItem>Sammuek</MenuItem>
                    <MenuItem>Sammuek</MenuItem>
                    <MenuItem>Sammuek</MenuItem>
                  </TextField> */}
                </Box>
              </Box>
              <TableContainer>
                <Table sx={{ mt: 2 }}>
                  <TableHead
                    sx={{ bgcolor: "background.default", borderRadius: "5px" }}
                  >
                    <TableRow>
                      <TableCell sx={{whiteSpace:'nowrap'}}>Date & Time</TableCell>
                      <TableCell sx={{whiteSpace:'nowrap'}}>Name of diner</TableCell>
                      <TableCell align="center" sx={{whiteSpace:'nowrap'}}>Party Size</TableCell>
                      <TableCell align="center"sx={{whiteSpace:'nowrap'}}>Seating Area</TableCell>
                      <TableCell align="center" sx={{whiteSpace:'nowrap'}}>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedReservations && sortedReservations
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.map((item, index) => (
                        <TableRow
                          key={index}
                          sx={{ bgcolor: "#333" }}
                          hover
                          onDoubleClick={() => handleDetailsScreen(item)}
                          onClick={() =>
                            !isNostalgia && handleDetailsScreen(item)
                          }
                        >
                          <TableCell sx={{ fontSize: "12px" }}>
                            {formatDate2(item?.Date) } - {formatTime(item?.Time)}
                          </TableCell>
                          <TableCell sx={{ fontSize: "12px" }}>
                            {item?.DinnerName}
                          </TableCell>
                          <TableCell align="center" sx={{ fontSize: "12px" }}>
                            {item?.PartySize}
                          </TableCell>
                          <TableCell align="center" sx={{ fontSize: "12px" }}>
                            {item?.SeatingArea}
                          </TableCell>
                          {isNostalgia && !item?.IsConfirmed ? (
                            <TableCell
                              onClick={() => {
                                setSingleReservation(item);
                                setOpen3(true);
                              }}
                              align="center"
                              sx={{ fontSize: "12px" }}
                            >
                              <Button
                                variant="outlined"
                                sx={{
                                  color: "#fff",
                                  fontSize: "12px",
                                  borderColor: "#fff",
                                }}
                                endIcon={<BsBookmarkCheckFill />}
                              >
                                Confirm Reservation
                              </Button>
                            </TableCell>
                          ) : (
                            <TableCell align="center" sx={{ fontSize: "12px" }}>
                              {item?.Status === "Expected"
                                ? "Reservation Confirmed"
                                : item?.Status}
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                page={page}
                rowsPerPage={rowsPerPage}
                totalRows={tableData?.length}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Calender
          future={false}
          past={false}
          onChildValue={(value) => setValue(value)}
        />
        <Box sx={{ p: 2 }} align="right">
          <Button
            onClick={() => {
              handleClose();
              handleFilter();
            }}
            variant="contained"
            sx={{ px: 4 }}
          >
            Filter
          </Button>
        </Box>
      </Popover>

      <Modal
        open={open3}
        onClose={() => setOpen3(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{ fontSize: "14px", color: "#fff", textAlign: "center" }}
          >
            Are you sure you want to approve{" "}
            <b style={{ color: "#19A662" }}>
              {" "}
              {singleReservation?.DinnerName || "--"}
            </b>{" "}
            Reservation
          </Typography>
          <Box sx={{ display: "grid", placeContent: "center", mt: 2 }}>
            <BsBookmarkCheckFill style={{ fontSize: "40px" }} />
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: 2, mt: 3 }}
          >
            <Button
              variant="outlined"
              onClick={() => setOpen3(false)}
              fullWidth
              sx={{ color: "#fff", borderColor: "#fff" }}
            >
              No, Close
            </Button>
            <Button
              onClick={() => handleApproveReservation()}
              variant="contained"
              fullWidth
              sx={{ bgcolor: "#19A662", "&:hover": { bgcolor: "#19A662b7" } }}
            >
              Yes, Approve
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default GeneralOverview;

const formatDate2 = (dateString) => {
  const date = new Date(dateString);

  return date.toLocaleDateString("en-US", {
    weekday: "short", // "Tue"
    year: "numeric", // "2024"
    month: "short", // "Oct"
    day: "2-digit", // "22"
  });
};

const formatTime = (time) => {
  const [hours, minutes] = time.split(":");
  const date = new Date();
  date.setHours(hours, minutes);

  return new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }).format(date);
};
