import React from "react";
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  Box,
  IconButton,
} from "@mui/material";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

const Pagination = ({
  page,
  rowsPerPage,
  totalRows,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const startRow = page * rowsPerPage + 1;
  const endRow = Math.min(startRow + rowsPerPage - 1, totalRows);
  const rowsText = `${startRow}-${endRow} of ${totalRows}`;

  return (
    <Grid container sx={{ mt: 3, px: 4, py: 1 }}>
      <Grid
        item
        lg={4}
        md={4}
        xs={4}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Typography
          sx={{ color: "#ccc", fontSize: "13px", fontFamily: "outfit" }}
        >
          {rowsText}
        </Typography>
      </Grid>
      <Grid item lg={8} md={8} xs={8}>
        <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
          <Typography
            sx={{ color: "#ccc", fontSize: "13px", fontFamily: "outfit", display:{lg:'block', md:'block', sm:'none', xs:'none'} }}
          >
            You are currently on Page
          </Typography>
          <Select
            size="small"
            sx={{ fontFamily: "outfit", color: "#ccc", fontSize: "13px" }}
            value={rowsPerPage}
            onChange={(e) => onRowsPerPageChange(Number(e.target.value))}
          >
            {[5, 10, 15, 25, 50].map((value) => (
              <MenuItem
                key={value}
                sx={{ fontFamily: "outfit", color: "#ccc" }}
                value={value}
              >
                {value}
              </MenuItem>
            ))}
          </Select>
          <Box
            sx={{
              borderLeft: "1px solid #ccc",
              pl: { md: 4, xs: 2 },
              ml: { md: 3, xs: 2 },
            }}
          >
            <IconButton
              disabled={page === 0}
              onClick={() => onPageChange(page - 1)}
              sx={{
                width: "30px",
                height: "30px",
                border: "1px solid #ccc",
                "&.Mui-disabled": {
                  border: "1px solid #4242421a",
                },
              }}
            >
              <ChevronLeftRoundedIcon />
            </IconButton>
            <IconButton
              disabled={endRow === totalRows}
              onClick={() => onPageChange(page + 1)}
              sx={{
                ml: 4,
                width: "30px",
                height: "30px",
                border: "1px solid #ccc",
                "&.Mui-disabled": {
                  border: "1px solid #4242421a",
                },
              }}
            >
              <ChevronRightRoundedIcon />
            </IconButton>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Pagination;
